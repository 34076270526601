body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body {
    height: 100%;
}

* {
    box-sizing: border-box;
}

svg {
    height: 1em;
    width: 1em;
}

a {
    color: black;
}

.pink {
    color: #dfb6c9;
}

.purple {
    color: #b097c8;
}

.blue {
    color: #6289c6;
}

.azure {
    color: #5dc7c9;
}

.green {
    color: #9fd271;
}

.yellow {
    color: #ffcf3d;
}

.orange {
    color: #f08c4d;
}

.red {
    color: #f36553;
}

body > div {
    height: 100%;
}

main {
    height: 100%;
    display: grid;
    grid-template-rows: 25% 75%;
}

input {
    font-size: 2em;
    border-radius: 0.25em;
    padding: 0.25em;
    border: 0.1em solid black;
    outline: none;
}

.input {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    position: relative;
}

.copy {
    position: absolute;
    bottom: 0;
    right: 1.5rem;

    width: 2rem;
    height: 2rem;

    opacity: 0.2;

    -webkit-transition: opacity 150ms ease-in-out;

    transition: opacity 150ms ease-in-out;

    cursor: pointer;
}

.copy:hover {
    opacity: 1;
}

.copy:active {
    opacity: 0.3;
}

.github {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;

    opacity: 0.2;

    -webkit-transition: opacity 150ms ease-in-out;

    transition: opacity 150ms ease-in-out;

    cursor: pointer;

    display: -webkit-inline-flex;

    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
}

.github svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}

.github:hover {
    opacity: 1;
}

.result {
    text-align: center;
    font-size: 4rem;

    word-break: break-all;

    overflow-y: auto;

    border-radius: 0.25em;
    margin: 1rem;
    padding: 0.25rem;
    border: 0.1em dashed lightgrey;
}

form {
    display: none;
}

